import React from "react";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import { DisplayError } from "../../store/types";

interface Props {
  loading?: boolean;
  error?: DisplayError;
}

// @TODO need design for Alerting errors inline.  Use Sales Chip?
export const Status: React.FunctionComponent<Props> = ({
  loading,
  error,
  children
}) => {
  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        Something went wrong. Please try again.
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
