import React from "react";

import { Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  dateStringSubMonth,
  dateStringSubQuarterYear,
  dateStringSubWeek,
  dateStringSubYear
} from "../../../helpers/dates";
import { DateString } from "../../../types";

export const options = [
  {
    id: "one-week",
    label: "1 Week",
    getDateStr: dateStringSubWeek
  },
  {
    id: "one-month",
    label: "1 Month",
    getDateStr: dateStringSubMonth
  },
  {
    id: "three-month",
    label: "3 Months",
    getDateStr: dateStringSubQuarterYear
  },
  {
    id: "one-year",
    label: "1 Year",
    getDateStr: dateStringSubYear
  }
];

type Props = {
  selected: DateString;
  onSelect: (selectedDate: DateString) => void;
};

// @TODO - we're duplicating sales status date selector here
export function SelectDate({ selected, onSelect }: Props) {
  const useStyles = makeStyles(theme => ({
    group: {
      backgroundColor: theme.palette.common.white
    },
    button: {
      textTransform: "capitalize",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      },
      "&:active": {
        boxShadow: "none"
      }
    }
  }));

  const classes = useStyles();

  return (
    <ButtonGroup className={classes.group} size="large">
      {options.map(option => {
        const optionDate = option.getDateStr;
        const optionSelected = selected === optionDate;
        return (
          <Button
            className={optionSelected ? classes.button : ""}
            key={option.id}
            onClick={() => onSelect(optionDate)}
            variant={optionSelected ? "contained" : "outlined"}
          >
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
