import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Form } from "./Form";
import { WLR3Address } from "./api/availabilityCheckApi";
import { useBroadbandSearch } from "./hooks/useBroadbandSearch";
import { ResultsTable } from "./ResultsTable";
import { StatusChip } from "@akj-dev/design-system";

export enum CalcType {
  Business = "Business",
  Residential = "Residential"
}

export const AvailabilityCheck = () => {
  const [calcType, setCalcType] = useState(CalcType.Business);
  const [cli, setCli] = useState("");
  const [address, setAddress] = useState<WLR3Address | "">("");

  const { data, error } = useBroadbandSearch(address, cli);

  return (
    <div>
      <h1>Availability Check</h1>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Form
            calcType={calcType}
            setCalcType={setCalcType}
            address={address}
            setAddress={setAddress}
            cli={cli}
            setCli={setCli}
          />
        </Grid>
        <Grid item sm={12}>
          {error ? (
            <StatusChip
              type="error"
              title="Problem performing availability check"
              message={error.message}
            />
          ) : (
            <>
              {data && (
                <ResultsTable calcType={calcType} searchResults={data} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
