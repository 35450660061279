import { OrderStatus } from "../types";

export function getOrderStatusDisplayLabel(status: OrderStatus) {
  switch (status) {
    case "in_progress":
      return "In Progress";
    case "committed":
      return "New";
    case "delayed":
      return "Delayed";
    case "attention":
      return "Attention";
    case "complete":
      return "Complete";
    case "cancelled":
      return "Cancelled";
    default:
      return "";
  }
}
