import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { getMuiThemePaletteColorByOrderType } from "../../../../helpers/getMuiThemePaletteColorByOrderType";
import { OrderType, ProductType } from "../../../../types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(1),
    textTransform: "capitalize",
    "& > *": {
      marginRight: theme.spacing(1)
    }
  }
}));

interface Props {
  orderNumber: number;
  productCategory: ProductType;
  orderType: OrderType;
}

export const SubHeader: React.FunctionComponent<Props> = ({
  orderNumber,
  productCategory,
  orderType
}) => {
  const classes = useStyles();

  const muiThemePaletteColor = getMuiThemePaletteColorByOrderType(orderType);

  return (
    <Box className={classes.root}>
      <Typography variant="h6">#{orderNumber}</Typography>
      <Typography variant="h6">{productCategory}</Typography>
      <Box color={`${muiThemePaletteColor}.main`} clone>
        <Typography variant="h6">{orderType}</Typography>
      </Box>
    </Box>
  );
};
