import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField
} from "@material-ui/core";
import { SelectField } from "@akj-dev/design-system";
import { LoaderButton, StatusChip } from "@akj-dev/design-system";
import { getAddressLine } from "./utils/getAddressLine";
import { WLR3Address } from "./api/availabilityCheckApi";
import { CalcType } from "./AvailabilityCheck";
import { useAddressSearch } from "./hooks/useAddressSearch";
import { useBroadbandSearch } from "./hooks/useBroadbandSearch";

interface FormProps {
  calcType: CalcType;
  setCalcType: React.Dispatch<React.SetStateAction<CalcType>>;
  address: WLR3Address | "";
  setAddress: React.Dispatch<React.SetStateAction<WLR3Address | "">>;
  cli: string;
  setCli: React.Dispatch<React.SetStateAction<string>>;
}

export const Form = ({
  calcType,
  setCalcType,
  cli,
  setCli,
  address,
  setAddress
}: FormProps) => {
  const [postcode, setPostcode] = useState("");
  const {
    isLoading: broadbandLoading,
    refetch: refetchBroadband
  } = useBroadbandSearch(address, cli);

  const {
    isLoading: addressesLoading,
    error: addressesError,
    data: addressData,
    refetch: refetchAddress
  } = useAddressSearch(postcode);

  const handleChangeCli = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCli(event.target.value);
  };

  const handleChangeCalcType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCalcType(event.target.value as CalcType);
  };

  const handleChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(event.target.value);
  };

  const handleChooseAddress = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setAddress(event.target.value as WLR3Address);
  };

  const handleClickLookup = () => {
    refetchAddress();
  };

  const handleClickSearch = () => {
    refetchBroadband();
  };

  const handleReset = () => {
    setCli("");
    setPostcode("");
    setAddress("");
  };

  return (
    <Card>
      <CardContent>
        <form>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <TextField
                label="CLI"
                name="cli"
                placeholder="eg. 01234567890"
                variant="outlined"
                fullWidth
                size="small"
                InputLabelProps={{
                  shrink: true
                }}
                value={cli}
                onChange={handleChangeCli}
              />
            </Grid>
            <Grid item sm={4}>
              <SelectField
                label="Throughput Calculation Type"
                margin="dense"
                name="throughput"
                value={calcType}
                onChange={handleChangeCalcType}
              >
                <MenuItem value="Business">Business</MenuItem>
                <MenuItem value="Residential">Residential</MenuItem>
              </SelectField>
            </Grid>
            <Grid item sm={4}>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>

            <Grid item sm={4}>
              <TextField
                label="Post code"
                name="postcode"
                placeholder="eg. DY5 1SJ"
                variant="outlined"
                fullWidth
                size="small"
                InputLabelProps={{
                  shrink: true
                }}
                value={postcode}
                onChange={handleChangePostcode}
              />
            </Grid>
            {addressData ? (
              <>
                <Grid item sm={4}>
                  <SelectField
                    value={address}
                    onChange={handleChooseAddress}
                    margin="dense"
                    label="House Number"
                    name="address"
                    id="address"
                    // InputLabelProps={{ // TODO: Can't be declared here...
                    //   shrink: true
                    // }}
                  >
                    {addressData.addressMatches?.map(
                      (a: WLR3Address, i: number) => (
                        <MenuItem
                          key={i}
                          // @ts-ignore - TODO: see https://github.com/mui-org/material-ui/issues/14286
                          value={a}
                        >
                          {getAddressLine(a)}
                        </MenuItem>
                      )
                    )}
                  </SelectField>
                </Grid>
                <Grid item sm={4}>
                  <LoaderButton
                    color="primary"
                    disabled={!address}
                    onClick={handleClickSearch}
                    loading={broadbandLoading}
                  >
                    Search
                  </LoaderButton>
                </Grid>
              </>
            ) : (
              <Grid item sm={4}>
                <LoaderButton
                  onClick={handleClickLookup}
                  disabled={postcode.length < 1}
                  loading={addressesLoading}
                  color="primary"
                >
                  Look-up
                </LoaderButton>
              </Grid>
            )}
            {addressesError ? (
              <Grid item sm={12}>
                <StatusChip type="error" message={addressesError.message} />
              </Grid>
            ) : null}
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};
