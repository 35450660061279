import { getJSON } from "../helpers";

import { endOfDay, formatISO9075, startOfDay } from "date-fns";
import { DateString, OrderStatus, OrderType, ProductType } from "../../types";
import { platformApiFetch } from "@akj-dev/affinity-platform";
import queryString from "query-string";

export interface SearchAPIResponse {
  customer_company_name: string;
  address: string;
  created_by_for_display: {
    name: string;
  };
  creation_date_timestamp: number;
  cust_reference: string;
  order_type: OrderType;
  order_number: number;
  order_product_category: ProductType;
  id: string | number;
  status_category: OrderStatus;
  billing_account: string;
}

export interface SearchAPIParams {
  order_type?: OrderType[];
  created_after: DateString;
  created_before: DateString;
  product_type?: ProductType;
  cust_reference?: string | null;
  order_status?: OrderStatus[];
  order_owner_id?: string | null;
  order_numbers?: (string | null)[];
  account?: string | null;
}

export function getSearch(params: SearchAPIParams) {
  const createdBefore = formatISO9075(
    endOfDay(new Date(params.created_before))
  );

  const createdAfter = formatISO9075(
    startOfDay(new Date(params.created_after))
  );

  return getJSON<SearchAPIResponse[]>("/Affinity/Orders/Search", {
    ...params,
    created_before: createdBefore,
    created_after: createdAfter
  });
}

export function getSearchCSVExport(params: SearchAPIParams) {
  const createdBefore = formatISO9075(
    endOfDay(new Date(params.created_before))
  );

  const createdAfter = formatISO9075(
    startOfDay(new Date(params.created_after))
  );

  const query = params
    ? "?" +
      queryString.stringify(
        {
          ...params,
          created_before: createdBefore,
          created_after: createdAfter
        },
        { arrayFormat: "bracket" }
      )
    : "";

  return platformApiFetch(
    `ExternalServices/v1/Affinity/Orders/SearchToCSV${query}`,
    {}
  )
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => response.blob());
}
