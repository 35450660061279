import {
  SvgIconComponent,
  Phone,
  Router,
  PhoneIphone,
  GroupWork
} from "@material-ui/icons";
import { ProductType } from "../types";

export function getIconByProductType(
  productType: ProductType
): SvgIconComponent {
  switch (productType) {
    case "Broadband":
      return Router;
    // case ProductType.CallsOnly:
    //   return Forum;
    case "Fixed Line":
      return Phone;
    case "Mixed":
      return GroupWork;
    case "Mobile":
      return PhoneIphone;
    default:
      return Router;
  }
}
