import React from "react";
import { useQueryParams, StringParam } from "use-query-params";
import { OrderStatusBoard } from "./Board/";
import { OrderDetail } from "./Detail";

// Issue https://auroratarget.tpondemand.com/entity/8659-dynamic-routes-not-supported prevents us
// from having a dynamic route for <Detail/> so to workaround we'll switch views based on a query param.
export const Status = () => {
  const [query] = useQueryParams({
    view: StringParam
  });

  if (query.view === "detail") {
    return <OrderDetail />;
  }

  return <OrderStatusBoard />;
};
