import { decodeArray } from "use-query-params";
import { DateString, ParsedQueryParam } from "../types";
import { toDateString } from "./dates";

export function decodePermittedArrayParam<T extends string[]>(
  input: ParsedQueryParam,
  permittedValues: T
) {
  const decoded = (decodeArray(input) || []).filter(x => x != null) as string[];
  return permittedValues.filter(type => decoded.includes(type)) as T;
}

export function decodeDateStringParam(
  input: ParsedQueryParam
): DateString | undefined {
  if (typeof input === "string") {
    try {
      return toDateString(input);
    } catch (e) {
      console.warn(e);
    }
  }
}
