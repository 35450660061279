import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";

import { Trigger } from "../types";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      margin: 0
    },
    chip: {
      margin: theme.spacing(0.5)
    }
  })
);

type Props = {
  triggers: Array<Trigger>;
};

const Component: React.FunctionComponent<Props> = ({ triggers }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {triggers.map((trigger: Trigger) => (
        <Chip
          size="small"
          label={trigger.value}
          key={trigger.id}
          className={classes.chip}
        />
      ))}
    </div>
  );
};

export default Component;
