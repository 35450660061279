import * as actions from "./actionTypes";

export const fetch = (filters: any) => {
  return { type: actions.FETCH, filters };
};

export const remove = (id: number) => {
  return { type: actions.REMOVE, payload: { id } };
};

export const setStatus = (id: number, is_active: Boolean) => {
  return {
    type: actions.SET_STATUS,
    payload: { id, is_active: is_active ? 1 : 0 }
  };
};
