import u from "updeep";

import * as actions from "./actionTypes";

const initialState = {
  accounts: {
    error: "",
    loading: false,
    options: []
  },

  users: {
    error: "",
    loading: false,
    options: []
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SEARCH_ACCOUNTS:
      return u(
        {
          accounts: {
            error: "",
            loading: true
          }
        },
        state
      );
    case actions.SEARCH_ACCOUNTS_OK:
      return u(
        {
          accounts: {
            error: "",
            loading: false,
            options: action.payload.data.results
          }
        },
        state
      );
    case actions.SEARCH_ACCOUNTS_FAILED:
      return u(
        {
          accounts: {
            loading: false,
            options: [],
            ...action.payload
          }
        },
        state
      );
    case actions.SEARCH_USERS:
      return u(
        {
          users: {
            error: "",
            loading: true
          }
        },
        state
      );
    case actions.SEARCH_USERS_OK:
      return u(
        {
          users: {
            error: "",
            loading: false,
            options: action.payload
          }
        },
        state
      );
    case actions.SEARCH_USERS_FAILED:
      return u(
        {
          users: {
            loading: false,
            options: [],
            ...action.payload
          }
        },
        state
      );
    default:
      return state;
  }
};
