import { getJSON, postJSON } from "./";

export function create(params: any) {
  let contacts = params.contacts.map((contact: any) => ({
    email: contact.email,
    name: contact.full_name
  }));

  if (params.email && params.name) {
    contacts.push({ name: params.name, email: params.email });
  }

  // We need to encode "statuses" and "contacts"
  let query = {
    contacts: JSON.stringify(contacts),
    statuses: JSON.stringify(params.statuses),
    type: params.type,
    account_id: params.account_id
  };

  return postJSON("ObjectNotifications/Subscribe", query);
}

export function paginate(params: any) {
  return getJSON("ObjectNotifications/Search", params);
}

export function remove(id: number) {
  return postJSON("ObjectNotifications/Remove/" + id);
}

export function setStatus(params: any) {
  return postJSON("ObjectNotifications/SetIsActive", params);
}
