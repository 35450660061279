import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Check, Delete } from "@material-ui/icons";
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme =>
  createStyles({
    danger: {
      color: theme.palette.error.main
    },
    success: {
      color: theme.palette.success.main
    }
  })
);

type Props = {
  id: number;
  onClick: (id: number) => void;
};

const Component: React.FunctionComponent<Props> = ({ id, onClick }) => {
  const classes = useStyles();

  const [showConfirmationButton, setShowConfirmationButton] = useState(false);
  const [loading, setLoading] = useState(false);

  // Show back delete button after 3 seconds
  // if user didn't clicked confirm
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfirmationButton(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [showConfirmationButton]);

  const handleConfirm = () => {
    onClick(id);
    setLoading(true);
  };

  if (loading) {
    return (
      <Button
        color="secondary"
        disabled
        startIcon={<CircularProgress size={20} />}
      >
        Deleting
      </Button>
    );
  }

  if (showConfirmationButton) {
    return (
      <Button
        className={classes.success}
        color="primary"
        onClick={handleConfirm}
        startIcon={<Check />}
        size="medium"
      >
        Confirm
      </Button>
    );
  }
  return (
    <Button
      className={classes.danger}
      color="secondary"
      onClick={() => setShowConfirmationButton(true)}
      startIcon={<Delete />}
      size="medium"
    >
      Delete
    </Button>
  );
};

export default Component;
