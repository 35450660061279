import React from "react";
import { parseISO } from "date-fns";
import Box from "@material-ui/core/Box";
import { DatePicker } from "@material-ui/pickers";
import { DateString } from "../../../../../types";
import { toDateString } from "../../../../../helpers/dates";

type Props = {
  dateFromSelected: DateString;
  dateToSelected: DateString;
  onDateFromSelect: (date: DateString) => void;
  onDateToSelect: (date: DateString) => void;
  disabled?: boolean;
};

export const DateRangeSelect = ({
  dateFromSelected,
  dateToSelected,
  onDateFromSelect,
  onDateToSelect,
  disabled
}: Props) => {
  const dateFrom = parseISO(dateFromSelected);
  const dateTo = parseISO(dateToSelected);
  return (
    <Box display="flex" justifyContent="space-between">
      <Box marginRight={0.5}>
        <DatePicker
          size="small"
          disabled={disabled}
          inputVariant="outlined"
          maxDate={dateTo}
          disableToolbar
          variant="inline"
          format="dd/MM/yyy"
          margin="none"
          id="date-from"
          label="from"
          value={dateFrom}
          onChange={date => {
            const dateString = toDateString(date || "");
            onDateFromSelect(dateString);
          }}
        />
      </Box>
      <Box marginLeft={0.5}>
        <DatePicker
          size="small"
          disabled={disabled}
          inputVariant="outlined"
          minDate={dateFrom}
          disableToolbar
          variant="inline"
          format="dd/MM/yyy"
          margin="none"
          id="date-to"
          label="to"
          value={dateTo}
          onChange={date => {
            const dateString = toDateString(date || "");
            onDateToSelect(dateString);
          }}
        />
      </Box>
    </Box>
  );
};
