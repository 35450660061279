import React from "react";
import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1)
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: `0 ${theme.spacing(0.5)}px`,
    fontSize: theme.typography.body2.fontSize,
    "& th": {
      textAlign: "left",
      width: 150
    }
  }
}));

interface Props {
  owner?: string;
  address?: string;
  reference?: string;
}

export function OrderInfo({ owner, address, reference }: Props) {
  const classes = useStyles();

  return (
    <Card raised className={classes.root}>
      <table className={classes.table}>
        <tbody>
          {owner && (
            <tr>
              <th>Created by</th>
              <td>{owner}</td>
            </tr>
          )}
          {address && (
            <tr>
              <th>Address</th>
              <td>{address}</td>
            </tr>
          )}
          {reference && (
            <tr>
              <th>Reference</th>
              <td>{reference}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
}
