import React from "react";
import download from "downloadjs";
import { getSearchCSVExport } from "../../../../api/v1/search";
import {
  Box,
  Button,
  makeStyles,
  List,
  ListItem,
  CircularProgress
} from "@material-ui/core";
import { AccountSelect } from "./filterControls/AccountSelect";
import { DateRangeSelect } from "./filterControls/DateRangeSelect";
import { MultiSelectFree } from "./filterControls/MultiSelectFree";
import { MultiSelect } from "./filterControls/MultiSelect";
import { FreeSearch } from "./filterControls/FreeSearch";
import { SnackbarProvider, useSnackbar } from "./SnackbarProvider";
import { getExternalUsersAutocomplete } from "../../../../api/v1/externalUsersAutcomplete";

import {
  OrderType,
  orderTypes,
  OrderStatus,
  orderStatuses
} from "../../../../types";
import { AccountOption, StatusBoardFilters } from "../types";
import { FilterControlConstants } from "../enums";
import { ProductTypeSelect } from "./filterControls/ProductTypeSelect";
import { getOrderStatusDisplayLabel } from "../../../../helpers/getOrderStatusDisplayLabel";
import { getAccountAutocomplete } from "../../../../api/v1/accountAutocomplete";
import { useState } from "react";
import { useEffect } from "react";

type Props = {
  filters: StatusBoardFilters;
  onApplyFilters: (filtersUpdate: Partial<StatusBoardFilters>) => void;
  updateOrderOwnerAccountsCache: (accountOption: AccountOption) => void;
  updateEndUserAccountsCache: (accountOption: AccountOption) => void;
  ownerAccountsCache: AccountOption[];
  endUserAccountsCache: AccountOption[];
  onReset: () => void;
};

const useStyles = makeStyles(theme => ({
  resetButton: {
    padding: 0,
    transform: "translateY(-2px)",
    marginLeft: theme.spacing(1)
  },
  csvButton: {
    marginLeft: theme.spacing(1)
  }
}));

const FilterMenuComponent = ({
  filters,
  onApplyFilters,
  onReset,
  updateOrderOwnerAccountsCache,
  updateEndUserAccountsCache,
  ownerAccountsCache,
  endUserAccountsCache
}: Props) => {
  const classes = useStyles();
  const { addSnackbar } = useSnackbar();
  const [filterSelections, setFilterSelections] = useState<StatusBoardFilters>(
    filters
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFilterSelections(filters);
  }, [filters]);

  const handleUpdateFilterSelections = (
    updates: Partial<StatusBoardFilters>
  ) => {
    setFilterSelections({ ...filterSelections, ...updates });
  };

  const findOrderOwnerNameById = (id: string | number) => {
    return (
      ownerAccountsCache.find(pair => pair.id.toString() === id.toString())
        ?.name || ""
    );
  };

  const findEndUserNameById = (id: string | number) => {
    return (
      endUserAccountsCache.find(pair => pair.id.toString() === id.toString())
        ?.name || ""
    );
  };

  const handleCSVDownload = async () => {
    setIsLoading(true);
    onApplyFilters(filterSelections);
    try {
      const response = await getSearchCSVExport(filterSelections);
      download(response, "orders.csv");
    } catch (e) {
      addSnackbar(
        "Failed to Download CSV at this time. Please try again later",
        "error"
      );
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <Box paddingX={1.5} paddingTop={1.5} paddingBottom={1.25} width={432}>
      <Box display="flex" fontWeight={600}>
        Filters
        <Button
          color="primary"
          className={classes.resetButton}
          onClick={onReset}
          variant="text"
        >
          RESET
        </Button>
      </Box>
      <List disablePadding>
        <ListItem disableGutters>
          <MultiSelectFree
            label="Order Number"
            id="order-numbers-filter-control"
            value={filterSelections.order_numbers}
            onSelect={order_numbers =>
              handleUpdateFilterSelections({ order_numbers })
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <DateRangeSelect
            dateFromSelected={filterSelections.created_after}
            dateToSelected={filterSelections.created_before}
            onDateFromSelect={created_after =>
              handleUpdateFilterSelections({ created_after })
            }
            onDateToSelect={created_before =>
              handleUpdateFilterSelections({ created_before })
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <AccountSelect
            selected={
              filterSelections.account
                ? {
                    id: filterSelections.account,
                    name: findEndUserNameById(filterSelections.account)
                  }
                : null
            }
            onSelect={account => {
              handleUpdateFilterSelections({ account: account?.id });
              if (account) {
                updateEndUserAccountsCache(account);
              }
            }}
            optionsService={getAccountAutocomplete}
            wait={FilterControlConstants.OptionsThrottleWait}
            label="Company Name / Reference Number"
            id="end-user-account-filter-control"
          />
        </ListItem>
        <ListItem disableGutters>
          <MultiSelect<OrderType>
            id="order-type-filter-control"
            label="Order Type"
            value={filterSelections.order_type}
            onSelect={order_type =>
              handleUpdateFilterSelections({ order_type })
            }
            options={orderTypes.slice()}
          />
        </ListItem>
        <ListItem disableGutters>
          <AccountSelect
            selected={
              filterSelections.order_owner_id
                ? {
                    id: filterSelections.order_owner_id,
                    name: findOrderOwnerNameById(
                      filterSelections.order_owner_id
                    )
                  }
                : null
            }
            onSelect={account => {
              handleUpdateFilterSelections({ order_owner_id: account?.id });
              if (account) {
                updateOrderOwnerAccountsCache(account);
              }
            }}
            optionsService={getExternalUsersAutocomplete}
            wait={FilterControlConstants.OptionsThrottleWait}
            label="Created By"
            id="order-owner-filter-control"
          />
        </ListItem>
        <ListItem disableGutters>
          <MultiSelect<OrderStatus>
            id="order-status-filter-control"
            label="Order Status"
            value={filterSelections.order_status}
            onSelect={order_status =>
              handleUpdateFilterSelections({ order_status })
            }
            options={orderStatuses.slice()}
            getOptionLabel={option => {
              return getOrderStatusDisplayLabel(option as OrderStatus);
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ProductTypeSelect
            value={filterSelections.product_type}
            onSelect={product_type =>
              handleUpdateFilterSelections({ product_type })
            }
            id="product-type-filter-control"
          />
        </ListItem>
        <ListItem disableGutters>
          <FreeSearch
            label="Opportunity Name"
            id="customer-reference-filter-control"
            value={filterSelections.cust_reference || ""}
            onDebouncedChange={cust_reference =>
              handleUpdateFilterSelections({ cust_reference })
            }
            wait={FilterControlConstants.FreeSearchDebounceWait}
          />
        </ListItem>
      </List>
      <Box my={0.5}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onApplyFilters(filterSelections)}
        >
          Apply
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.csvButton}
          disabled={isLoading}
          onClick={handleCSVDownload}
        >
          Download as CSV
        </Button>
        {isLoading ? (
          <CircularProgress className={classes.csvButton} size={20} />
        ) : null}
      </Box>
    </Box>
  );
};

export const FilterMenu = (props: Props) => (
  <SnackbarProvider>
    <FilterMenuComponent {...props} />
  </SnackbarProvider>
);
