import { REQUEST_DATA, RequestDataAction } from "./types";
import { put, takeEvery, call } from "redux-saga/effects";
import { setData, setError } from "./actions";
import { getOverview, OverviewAPIResponse } from "../../api/v1/overview";
import { ErrorAPI } from "../../api/helpers";

function* fetchOverviewData(action: RequestDataAction) {
  try {
    const apiData: OverviewAPIResponse = yield call(
      getOverview,
      action.payload
    );
    yield put(setData(apiData));
  } catch (e) {
    const error: ErrorAPI = e;
    yield put(setError(error));
  }
}

export function* watchRequestOverviewData() {
  yield takeEvery(REQUEST_DATA, fetchOverviewData);
}
