import React from "react";
import { Box, Typography } from "@material-ui/core";
import { getMuiThemePaletteColorByOrderType } from "../../../../helpers/getMuiThemePaletteColorByOrderType";
import { getIconByProductType } from "../../../../helpers/getIconByProductType";
import { OrderType, ProductType } from "../../../../types";

interface Props {
  orderType?: OrderType;
  productType?: ProductType;
  companyName?: string;
}

export const Header: React.FunctionComponent<Props> = ({
  orderType,
  productType,
  companyName,
  children
}) => {
  const muiThemePaletteColor = orderType
    ? getMuiThemePaletteColorByOrderType(orderType)
    : "primary";

  const Icon = productType ? getIconByProductType(productType) : null;

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginY={1}
      >
        <Typography variant="h2">
          Order
          {companyName && ` - ${companyName}`}
        </Typography>
        <Box
          color={`${muiThemePaletteColor}.main`}
          display="flex"
          data-cy="product-type-icon"
        >
          {Icon && <Icon data-testid="product-type-icon" fontSize="large" />}
        </Box>
      </Box>
      {children}
    </React.Fragment>
  );
};
