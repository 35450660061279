import { REQUEST_DATA, RequestDataAction } from "./types";
import { put, takeEvery, call } from "redux-saga/effects";
import { setData, setError } from "./actions";
import { getDetailById, ViewAPIResponse } from "../../api/v1/view";
import { ErrorAPI } from "../../api/helpers";

function* fetchDetailDataById(action: RequestDataAction) {
  try {
    const apiData: ViewAPIResponse = yield call(getDetailById, action.payload);
    yield put(setData(apiData));
  } catch (e) {
    const error: ErrorAPI = e;
    yield put(setError(error));
  }
}

export function* watchRequestDetailById() {
  yield takeEvery(REQUEST_DATA, fetchDetailDataById);
}
