import {
  OverviewAPIResponse,
  OverviewAPIParams,
  Overview
} from "../../api/v1/overview";
import { ProductType } from "../../types";
import { DisplayError } from "../types";

export const SET_ERROR = "overview.SET_ERROR";
export const REQUEST_DATA = "overview.REQUEST_DATA";
export const SET_DATA = "overview.SET_DATA";

// STATE

export type OrderOverview = { product_type: ProductType } & Overview;

export interface OverviewState {
  fetching: boolean;
  error?: DisplayError;
  data: OrderOverview[];
}

// ACTIONS

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: DisplayError;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload?: OverviewAPIParams;
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: OverviewAPIResponse;
}

export type OverviewActionTypes =
  | SetErrorAction
  | RequestDataAction
  | SetDataAction;
