import {
  OverviewState,
  OverviewActionTypes,
  SET_ERROR,
  SET_DATA,
  REQUEST_DATA,
  OrderOverview
} from "./types";
import { OverviewAPIResponse } from "../../api/v1/overview";
import { ProductType } from "../../types";

const initialState: OverviewState = {
  fetching: false,
  error: undefined,
  data: []
};

export function overviewReducer(
  state = initialState,
  action: OverviewActionTypes
): OverviewState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    case SET_DATA:
      return {
        ...state,
        error: undefined,
        fetching: false,
        data: mapOverviewApiResponseToOrderOverviews(action.payload)
      };
    default:
      return state;
  }
}

function mapOverviewApiResponseToOrderOverviews(
  apiResponse: OverviewAPIResponse
): OrderOverview[] {
  return (Object.keys(apiResponse) as Array<keyof typeof apiResponse>)
    .filter(
      // this API has been known to include extraneous fields :-/
      key =>
        key === "Broadband" ||
        key === "Fixed Line" ||
        key === "Mixed" ||
        key === "Mobile"
    )
    .map(key => ({
      product_type: key as ProductType,
      ...apiResponse[key]
    }));
}
