import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { LocationDescriptorObject } from "history";

type Props = {
  title: string;
  value: number;
  to: LocationDescriptorObject | string;
};

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 24,
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(0.25),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.25),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    textAlign: "center",
    color: theme.palette.text.primary,
    transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    }
  }
}));

export const OrdersSummaryCardItem = ({ title, value, to }: Props) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.root}>
      <Box>
        <Box>{value}</Box>
        <Box fontSize={12}>{title}</Box>
      </Box>
    </Link>
  );
};
