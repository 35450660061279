import _ from "lodash";
import { WLR3Address } from "../api/availabilityCheckApi";

/**
 * Converts a WLR3 / SI formatted address into a single string.
 * Copied from packages/affinity-sales/src/helpers/addresses.js
 * TODO: Shared fn when sales refactor is merged?
 */
export function getAddressLine(address: WLR3Address) {
  return _.compact([
    address["organisationName"],
    address["poBox"],
    address["subPremises"],
    address["premisesName"],
    _.compact([
      address["thoroughfareNumber"],
      address["thoroughfareName"]
    ]).join(" "),
    address["doubleDependentLocality"],
    address["locality"],
    address["postTown"],
    address["county"],
    address["postcode"]
  ]).join(", ");
}
