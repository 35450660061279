import React from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";

import Autocomplete from "../../../../components/Autocomplete";
import TransferUsers from "./TransferUsers";

import { constants } from "../../../../helpers";
import { Trigger } from "../../types";

import {
  Account,
  Autocomplete as AutocompleteInterface,
  User
} from "../../../../types";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0.5),
    marginLeft: "auto"
  },
  divider: {
    marginBottom: theme.spacing(1.5)
  },
  group: {
    marginBottom: theme.spacing(1.5)
  },
  selectedType: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  title: {
    marginBottom: theme.spacing(1)
  }
}));

function GridGroup({
  children,
  title
}: {
  children: React.ReactNode;
  title?: string;
}) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.group}>
      {title && (
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
      )}

      <Grid container spacing={1}>
        {children}
      </Grid>
    </Grid>
  );
}

interface Props {
  fields: any;
  setFields: (fields: any) => void;
  onSubmit: (fields: any) => void;
  accounts: AutocompleteInterface<Account>;
  searchAccounts: (query: string) => void;
  users: AutocompleteInterface<User>;
}

function Component({
  fields,
  setFields,
  onSubmit,
  accounts,
  searchAccounts,
  users
}: Props) {
  const classes = useStyles();

  const handleChange = (field: string) => (event: any) => {
    setFields({
      [field]: event.target.value
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    onSubmit(fields);
  };

  return (
    <form
      name="createNotificationForm"
      autoComplete="off"
      onSubmit={handleSubmit}
      style={{ width: "100%" }}
    >
      <Card variant="outlined">
        <CardContent>
          <GridGroup title="Select notification trigger">
            <Grid item xs={12} md={4}>
              <Autocomplete
                label="Select Account"
                loading={accounts.loading}
                name="account_id"
                onSearch={(query: string) => searchAccounts(query)}
                onSelect={(account: Account) =>
                  setFields({ account_id: account ? account.id : "" })
                }
                options={accounts.options}
                placeholder="Type at least 3 characters to search an account"
                value={
                  accounts.options.find(
                    (account: Account) => account.id === fields.account_id
                  ) || null
                }
                valueFrom="id"
                textFrom="name"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="type"
                label="Select Product"
                name="type"
                onChange={handleChange("type")}
                required
                select
                size="small"
                value={fields.type || ""}
                variant="outlined"
                inputProps={{
                  "data-testid": "type"
                }}
              >
                {constants.notificationTypes.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                label="Select Trigger"
                multiple
                name="statuses"
                onSelect={(triggers: Array<Trigger>) =>
                  setFields({
                    statuses: triggers.map(trigger => trigger.value)
                  })
                }
                options={constants.triggers.filter(trigger =>
                  trigger.types.find(type => type === fields.type)
                )}
                textFrom="value"
                value={constants.triggers.filter(
                  trigger =>
                    trigger.types.find(type => type === fields.type) &&
                    (fields.statuses || []).indexOf(trigger.value) >= 0
                )}
              />
            </Grid>
          </GridGroup>

          <GridGroup title="Notify organisational users">
            <TransferUsers
              onSelect={(users: any) => setFields({ contacts: users })}
              selected={fields.contacts}
              users={users}
            />
          </GridGroup>

          <GridGroup title="Add custom user">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Username"
                id="name"
                name="name"
                onChange={handleChange("name")}
                size="small"
                value={fields.name || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email Address"
                id="email"
                name="email"
                onChange={handleChange("email")}
                size="small"
                type="email"
                value={fields.email || ""}
                variant="outlined"
              />
            </Grid>
          </GridGroup>
        </CardContent>
        <CardActions>
          <Button
            className={classes.button}
            disabled={
              !fields.account_id ||
              !fields.type ||
              !fields.statuses.length ||
              !(fields.contacts.length || (fields.email && fields.name))
            }
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
          >
            Create Notification
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default Component;
