import { call, put, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as api from "../../api/notifications";

function* create(action: any): any {
  try {
    const response = yield call(api.create, action.payload);
    yield put({ type: actions.CREATE_OK, payload: response });

    // Redirect to Orders Notifications page
    window.location.href = "/orders/notifications";
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILED,
      payload: {
        error:
          "There was a problem when saving the notification. Please try again."
      }
    });

    console.log("create notification error:", error.message);
  }
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}
