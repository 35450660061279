// ORDER CARD

import React from "react";
import { format, fromUnixTime } from "date-fns";

import { Card, Divider, Typography, Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getIconByProductType } from "../../../../helpers/getIconByProductType";
import { getMuiThemePaletteColorByOrderType } from "../../../../helpers/getMuiThemePaletteColorByOrderType";
import { Order } from "../../../../types";

type Props = {
  order: Order;
};

export const OrderCard = ({
  order: {
    order_type,
    order_product_category,
    order_number,
    customer_company_name,
    cust_reference,
    billing_account,
    created_by_for_display,
    creation_date_timestamp,
    id
  }
}: Props) => {
  let muiThemePaletteColor = getMuiThemePaletteColorByOrderType(order_type);

  const Icon = getIconByProductType(order_product_category);

  const useStyles = makeStyles(theme => ({
    link: {
      width: "100%",
      textDecoration: "none"
    },
    card: {
      borderBottomWidth: theme.shape.borderRadius,
      borderBottomColor: theme.palette[muiThemePaletteColor].main,
      borderBottomStyle: "solid",
      padding: theme.spacing(1),
      paddingBottom: 0,
      textTransform: "capitalize",
      "& .MuiTypography-root": {
        fontSize: 13
      },
      "&:hover": {
        boxShadow: theme.shadows[12]
      }
    }
  }));

  const classes = useStyles();

  return (
    <Link
      to={location => ({ ...location, search: `?view=detail&id=${id}` })}
      className={classes.link}
    >
      <Card className={classes.card} elevation={3}>
        <Box display="flex" marginBottom={0.25}>
          <Box clone flex={1}>
            <Typography component="div" noWrap color="textSecondary">
              {order_product_category}
            </Typography>
          </Box>
          <Box clone marginLeft={0.5}>
            <Typography component="div" align="right" color="textSecondary">
              #{order_number}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" marginBottom={0.25}>
          <Typography
            component="div"
            noWrap
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            {customer_company_name}
          </Typography>
        </Box>
        <Box display="flex" marginBottom={0.25}>
          <Box clone flex={1}>
            <Typography component="div" noWrap>
              {cust_reference}
            </Typography>
          </Box>
          <Box clone marginLeft={0.5}>
            <Typography component="div" align="right" color="textSecondary">
              #{billing_account}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" marginBottom={0.25}>
          <Box clone flex={1}>
            <Typography
              component="div"
              noWrap
              color="textSecondary"
              style={{ fontSize: 14 }}
            >
              {created_by_for_display?.name}
            </Typography>
          </Box>
        </Box>
        <Box marginTop={0.5}>
          <Divider />
        </Box>

        <Box
          display="flex"
          marginTop={0.5}
          marginBottom={0.5}
          justifyContent="space-between"
        >
          <Box color={`${muiThemePaletteColor}.main`} display="flex">
            <Icon
              fontSize="small"
              style={{ marginTop: -3, marginRight: 4 }}
              data-testid="order-card-icon"
            />
            <Typography component="div" noWrap color="inherit">
              {order_type}
            </Typography>
          </Box>
          <Typography component="div" align="right" style={{ fontWeight: 600 }}>
            {formatDate(creation_date_timestamp)}
          </Typography>
        </Box>
      </Card>
    </Link>
  );
};

function formatDate(timestamp: number): string {
  try {
    return format(fromUnixTime(timestamp), "dd MMM ''yy");
  } catch (e) {
    return "";
  }
}
