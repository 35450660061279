import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { ProductType, productTypes } from "../../../../../types";

type Props = {
  onSelect: (selected: ProductType) => void;
  id: string;
  value?: ProductType | null;
  [x: string]: any;
};

export const ProductTypeSelect = ({ onSelect, id, value, ...rest }: Props) => {
  return (
    <TextField
      label="Product Type"
      select
      fullWidth
      variant="outlined"
      size="small"
      id={id}
      value={value || ""}
      onChange={event => {
        const value = event.target.value as ProductType;
        onSelect(value);
      }}
      {...rest}
    >
      {productTypes.slice().map(type => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </TextField>
  );
};
