import { DisplayError } from "../types";
import {
  NotesActionTypes,
  REQUEST_DATA,
  INSERT_NOTES,
  SET_ERROR,
  INSERT_NOTE,
  SET_SUBMIT_NOTE_ERROR,
  REQUEST_POST_NOTE,
  SET_DRAFT_FORM_DATA
} from "./types";
import { NoteAPIResponse } from "../../api/v1/notes";

export function setNotesError(
  orderId: string,
  errorForDisplay: DisplayError
): NotesActionTypes {
  return {
    type: SET_ERROR,
    payload: { orderId, error: errorForDisplay }
  };
}

export function requestNotesDataById(id: string): NotesActionTypes {
  return {
    type: REQUEST_DATA,
    payload: id
  };
}

export function addNotesDataById(
  orderId: string,
  data: NoteAPIResponse[]
): NotesActionTypes {
  return {
    type: INSERT_NOTES,
    payload: { orderId, notes: data }
  };
}

export function insertNoteToOrderId(
  orderId: string,
  note: NoteAPIResponse
): NotesActionTypes {
  return {
    type: INSERT_NOTE,
    payload: { orderId, note }
  };
}

export function setSubmitNoteError(
  orderId: string,
  errorForDisplay: DisplayError
): NotesActionTypes {
  return {
    type: SET_SUBMIT_NOTE_ERROR,
    payload: { orderId, error: errorForDisplay }
  };
}

export function requestPostNoteToOrderId(orderId: string): NotesActionTypes {
  return {
    type: REQUEST_POST_NOTE,
    payload: { orderId }
  };
}

export function setDraftNoteFormDataAction(
  orderId: string,
  content: string
): NotesActionTypes {
  return {
    type: SET_DRAFT_FORM_DATA,
    payload: { orderId, content }
  };
}
