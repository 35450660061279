import {
  RequestDataAction,
  REQUEST_DATA,
  RequestPostNoteAction,
  REQUEST_POST_NOTE,
  NotesState
} from "./types";
import {
  NoteAPIResponse,
  getNotesByOrderId,
  postNoteByOrderId
} from "../../api/v1/notes";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { ErrorAPI } from "../../api/helpers";
import {
  addNotesDataById,
  setNotesError,
  setSubmitNoteError,
  insertNoteToOrderId
} from "./actions";
import { RootState } from "../rootReducer";

function* fetchOrderNotesDataById(action: RequestDataAction) {
  const requestedOrderId = action.payload;

  try {
    const apiData: NoteAPIResponse[] = yield call(
      getNotesByOrderId,
      requestedOrderId
    );

    yield put(addNotesDataById(requestedOrderId.toString(), apiData));
  } catch (e) {
    const error: ErrorAPI = e;
    yield put(setNotesError(requestedOrderId.toString(), error));
  }
}

function* addNoteToOrderById(action: RequestPostNoteAction) {
  const orderId = action.payload.orderId;
  const notesState: NotesState = yield select(
    (state: RootState) => state.notes
  );

  const content = notesState.draftNotes[orderId];

  if (content) {
    try {
      const apiData: NoteAPIResponse = yield call(
        postNoteByOrderId,
        orderId,
        content
      );

      yield put(insertNoteToOrderId(orderId.toString(), apiData));
    } catch (e) {
      const error: ErrorAPI = e;
      yield put(setSubmitNoteError(orderId.toString(), error));
    }
  }
}

export function* watchRequestNotesDataById() {
  yield takeEvery(REQUEST_DATA, fetchOrderNotesDataById);
}

export function* watchRequestPostNote() {
  yield takeEvery(REQUEST_POST_NOTE, addNoteToOrderById);
}
