import { platformApiFetch } from "@akj-dev/affinity-platform";
import queryString from "query-string";

function getHeaders() {
  let headers = new Headers();

  headers.append("Accept", "application/json");
  headers.append(
    "Content-type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );
  headers.append("pragma", "no-cache");
  headers.append("cache-control", "no-cache");

  return headers;
}

export function getJSON(url: string, params?: any, options?: any) {
  const query = params ? "?" + queryString.stringify(params) : "";

  delete (options || {}).params;

  return platformApiFetch(`ExternalServices/v1/${url}${query}`, {
    ...options,
    headers: {
      ...(options || {}).headers,
      ...getHeaders()
    }
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.status === "error") {
        throw Error(data.message);
      }
      return data;
    });
}

export async function postJSON(url: string, params?: any, options?: any) {
  return platformApiFetch(`ExternalServices/v1/${url}`, {
    ...options,
    body: queryString.stringify(params),
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      ...(options || {}).headers,
      ...getHeaders()
    },
    method: "POST"
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.status === "error") {
        throw Error(data.message);
      }
      return data;
    });
}
