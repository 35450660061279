import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

type Props = {
  onDelete?: () => void;
  value: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export const FilterChip = ({ onDelete, value }: Props) => {
  const classes = useStyles();
  return (
    <Chip
      onDelete={onDelete}
      size="small"
      className={classes.root}
      label={value}
    />
  );
};
