import React from "react";
import {
  TextField,
  Button,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Box
} from "@material-ui/core";
import { NoteConstants } from "../enums";

interface Props {
  content: string;
  onChange: (content: string) => void;
  onSubmit: () => void;
  submitting: boolean;
}

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(0.5)
  },
  cardActions: {
    padding: 0,
    paddingTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end"
  },
  form: {
    "& .MuiTextField-root": {
      width: "100%"
    },
    "& .MuiFormHelperText-root": {
      textAlign: "end"
    }
  }
}));

export const FormAddComment = ({
  content,
  onSubmit,
  onChange,
  submitting
}: Props) => {
  const classes = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const error = content.length > NoteConstants.newNoteLimit;
  const disabled = content.length < 1 || error || submitting;

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Card raised className={classes.card}>
          <CardContent>
            <TextField
              variant="outlined"
              id="add-comment-to-order"
              label="Your comment (max 3000 characters)"
              multiline
              rows={4}
              value={content}
              onChange={handleChange}
              error={error}
              helperText={
                content.length > NoteConstants.newNoteLimit - 600 &&
                `${content.length} / ${NoteConstants.newNoteLimit}`
              }
            />
            <CardActions className={classes.cardActions}>
              <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                type="submit"
              >
                {submitting ? "Saving..." : "Save Comment"}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};
