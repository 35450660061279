import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";

type Props = {
  id: string;
  label: string;
  value?: (string | null)[];
  onSelect: (selected: string[]) => void;
  [x: string]: any;
};

export const MultiSelectFree = ({
  id,
  value,
  onSelect,
  label,
  ...rest
}: Props) => {
  return (
    <Autocomplete
      fullWidth
      multiple
      size="small"
      id={id}
      options={[]}
      value={value}
      onChange={(e, selected) => {
        const value = selected.filter(x => typeof x === "string") as string[];
        onSelect(value);
      }}
      freeSolo
      renderTags={(value: any, getTagProps) =>
        value.map((option: any, index: any) => (
          <Chip size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} />
      )}
      {...rest}
    />
  );
};
