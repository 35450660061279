import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { TextField } from "@material-ui/core";

type Props = {
  onDebouncedChange: (value: string) => void;
  value: string;
  wait: number;
  [x: string]: any;
};

const Component = ({ value, onDebouncedChange, wait, ...props }: Props) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleDebouncedChange = useCallback(
    debounce((value: string) => {
      onDebouncedChange(value);
    }, wait),
    []
  );

  return (
    <TextField
      {...props}
      onChange={event => {
        setVal(event.target.value);
        handleDebouncedChange(event.target.value);
      }}
      value={val}
    />
  );
};

export default Component;
