import React, { useEffect } from "react";

import {
  Card,
  List,
  ListItem,
  makeStyles,
  Typography
} from "@material-ui/core";
import { Comment } from "./Comment";

import { useSelector, useDispatch } from "react-redux";

import { Status } from "../../../common/Status";
import { FormAddComment } from "./FormAddComment";
import {
  setDraftNoteFormDataAction,
  requestPostNoteToOrderId,
  requestNotesDataById
} from "../../../../store/notes/actions";
import { RootState } from "../../../../store/rootReducer";

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(0.5)
  }
}));

type Props = {
  orderId: string;
};

export const Comments: React.FunctionComponent<Props> = ({ orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notes =
    useSelector((state: RootState) => state.notes.notes[orderId]) || [];
  const loading = useSelector(
    (state: RootState) => !!state.notes.fetchingIds.find(id => id === orderId)
  );
  const error = useSelector((state: RootState) =>
    state.notes.errors.find(error => error.orderId === orderId)
  );

  const draftNote =
    useSelector((state: RootState) => state.notes.draftNotes[orderId]) || "";

  const draftSubmitting = useSelector(
    (state: RootState) => !!state.notes.submittingIds.find(id => id === orderId)
  );

  const handleDraftNoteChange = (value: string) => {
    dispatch(setDraftNoteFormDataAction(orderId, value));
  };

  const handleAddCommentFormSubmit = () =>
    dispatch(requestPostNoteToOrderId(orderId));

  useEffect(() => {
    dispatch(requestNotesDataById(orderId));
  }, [dispatch, orderId]);

  const comments = notes
    .slice(0)
    .sort((a, b) => b.date_modified_timestamp - a.date_modified_timestamp)
    .map(comment => (
      <ListItem key={comment.id}>
        <Comment
          timestamp={comment.date_modified_timestamp}
          createdBy={comment.created_by_for_display}
        >
          {comment.relevant_information_for_display}
        </Comment>
      </ListItem>
    ));

  const getNoCommentsMessage = () => (
    <ListItem>
      <Typography variant="body2">No comments added yet</Typography>
    </ListItem>
  );

  return (
    <React.Fragment>
      <FormAddComment
        content={draftNote}
        onChange={handleDraftNoteChange}
        onSubmit={handleAddCommentFormSubmit}
        submitting={draftSubmitting}
      />
      <Status loading={loading} error={error}>
        <Card raised className={classes.card}>
          <List data-cy="comments-list">
            {comments.length > 0 ? comments : getNoCommentsMessage()}
          </List>
        </Card>
      </Status>
    </React.Fragment>
  );
};
