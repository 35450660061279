import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Link,
  makeStyles
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { format, parseISO } from "date-fns";
import { TableConstants } from "../../../Dashboard/enums";
import { OrderComponent } from "../../../../store/detail/types";

interface Props {
  components: OrderComponent[];
}

export const OrderComponentsTable = ({ components }: Props) => {
  const cleanEmpty = (val?: string): string => val || TableConstants.Empty;

  const formatDate = (val?: string): string | undefined => {
    if (!val) {
      return undefined;
    }
    try {
      return format(parseISO(val), "dd/MM/yyyy");
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };

  const columns: {
    name: keyof OrderComponent;
    label: string;
    options?: {};
  }[] = [
    {
      name: "forecast_date",
      label: "Forecast Date",
      options: {
        customBodyRender: (val: OrderComponent["forecast_date"]) =>
          cleanEmpty(formatDate(val))
      }
    },
    {
      name: "id",
      label: "ID",
      options: { customBodyRender: cleanEmpty }
    },
    {
      name: "cli",
      label: "PIN / CLI",
      options: { customBodyRender: cleanEmpty }
    },
    {
      name: "product",
      label: "Product",
      options: { customBodyRender: cleanEmpty }
    },
    {
      name: "request_stage",
      label: "Status",
      options: { customBodyRender: cleanEmpty, sortDirection: "asc" }
    },
    {
      name: "submitted_date",
      label: "Date Submitted",
      options: {
        customBodyRender: (val: OrderComponent["submitted_date"]) =>
          cleanEmpty(formatDate(val))
      }
    },
    {
      name: "message",
      label: "Message",
      options: {
        customBodyRender: (message: OrderComponent["message"], meta: any) => {
          if (!message) {
            return cleanEmpty(message);
          }
          const id = components[meta.rowIndex]["id"];
          return <ViewMessage id={id.toString()}>{message}</ViewMessage>;
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    download: false,
    elevation: 1,
    filter: false,
    print: false,
    responsive: "scrollFullHeight",
    rowHover: false,
    selectableRows: "none",
    search: false,
    setTableProps: () => ({
      size: "small"
    }),
    viewColumns: false
  };

  return (
    <MUIDataTable
      title="Order Components"
      data={components}
      columns={columns}
      options={options}
    />
  );
};

const useDialogStyles = makeStyles(theme => ({
  paper: { minWidth: 400 },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500]
  }
}));

const ViewMessage: React.FC<{ id: string }> = ({ children, id }) => {
  const classes = useDialogStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return children ? (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle>
          Message for {id}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Link component="button" variant="body2" onClick={() => setOpen(true)}>
        <VisibilityIcon
          // vertically centering svg in buttons makes me sad :-(
          style={{ marginBottom: "-5px" }}
          fontSize="small"
        />{" "}
        View
      </Link>
    </React.Fragment>
  ) : null;
};
