import { getJSON } from "../helpers";

interface AccountAutocompleteAPIResponse {
  name: string;
  id: number;
}

export function getAccountAutocomplete(query: string) {
  return getJSON<AccountAutocompleteAPIResponse[]>("/Account/AutoComplete", {
    name: query
  }).then(results =>
    results.map(result => ({ name: result.name, id: result.id.toString() }))
  );
}
