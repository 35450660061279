import React from "react";
import { DataTable, StatusChip } from "@akj-dev/design-system";
import { CalcType } from "./AvailabilityCheck";
import { BroadbandSearchResponse } from "./api/availabilityCheckApi";
import { Box } from "@material-ui/core";

interface Props {
  calcType: CalcType;
  searchResults: BroadbandSearchResponse;
}

const columns = [
  { name: "name", label: "Name", sortable: true },
  { name: "supplier", label: "Supplier", sortable: true },
  { name: "type", label: "Type", sortable: true },
  {
    name: "dlSpeed",
    label: "Normally Available Download Speed",
    sortable: true
  },
  { name: "ulSpeed", label: "Normally Available Upload Speed", sortable: true },
  {
    name: "minDlSpeed",
    label: "Minimum Guaranteed Download Speed",
    sortable: true
  },
  { name: "leadTime", label: "Lead Time", sortable: true }
];

export const ResultsTable = ({ calcType, searchResults }: Props) => {
  const data = searchResults.products.map(product => {
    const rateCard =
      // TODO: Index type
      // @ts-ignore
      searchResults.broadband_rates[calcType][
        product.first_broadband_component.supplier_product_ref
      ][product.first_broadband_component.supplier_product_subtype || "ALL"];
    return [
      product.name,
      product.first_broadband_component.supplier,
      product.first_broadband_component.supplier_product_ref,
      // TODO: Confirm what values should be mapped here
      `${rateCard.estimated_download_range} Kbps`,
      `${rateCard.estimated_upload_range} Kbps`,
      `${rateCard.minimum_guaranteed_speed} Kbps`,
      product.first_broadband_component.estimated_lead_time?.lead_time
    ];
  });
  return (
    <>
      {searchResults.availability_message && (
        <Box mb={1}>
          <StatusChip
            type="error"
            title="Availability check failed"
            message={searchResults.availability_message}
          />
        </Box>
      )}
      <DataTable
        title={`Postcode: ${searchResults.original_query_params.postcode}, Exchange name: ${searchResults.exchange_details.exchange_name}, Exchange code: ${searchResults.exchange_details.exchange_code}, CP Name: ${searchResults.exchange_details.cp_name}`}
        columns={columns}
        data={data}
      />
    </>
  );
};
