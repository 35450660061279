// This file for types common across multiple Order views.

export type Autocomplete<T> = {
  error: string;
  loading: boolean;
  options: Array<T>;
};

export interface Account {
  id: string;
  name: string;
}

export interface User {
  id: string;
  full_name: string;
}

// This pattern is an alternative to using string Enums: https://thoughtbot.com/blog/the-trouble-with-typescript-enums
// The exported array is useful for iterating over the possible type values at runtime.
export const productTypes = [
  "Broadband",
  "Fixed Line",
  "Mixed",
  "Mobile"
] as const;
export type ProductType = typeof productTypes[number];

export const orderTypes = ["Provide"] as const;
export type OrderType = typeof orderTypes[number];

export const orderStatuses = [
  "committed",
  "in_progress",
  "delayed",
  "attention",
  "complete",
  "cancelled"
] as const;
export type OrderStatus = typeof orderStatuses[number];

export type Order = {
  customer_company_name: string;
  address: string;
  created_by_for_display: { name: string };
  creation_date_timestamp: number;
  cust_reference: string;
  order_type: OrderType;
  order_number: number;
  order_product_category: ProductType;
  id: string | number;
  billing_account: string;
  status_category: OrderStatus;
};

export type ParsedQueryParam = string | (string | null)[] | null | undefined;

// Nominal type - do not use directly
export enum DateStringBrand {
  _ = ""
}
export type DateString = string & DateStringBrand;
