import {
  SET_ERROR,
  OverviewActionTypes,
  REQUEST_DATA,
  SET_DATA
} from "./types";
import { OverviewAPIResponse, OverviewAPIParams } from "../../api/v1/overview";
import { DisplayError } from "../types";

export function setError(errorForDisplay: DisplayError): OverviewActionTypes {
  return {
    type: SET_ERROR,
    payload: errorForDisplay
  };
}

export function requestOverviewData(
  filters?: OverviewAPIParams
): OverviewActionTypes {
  return {
    type: REQUEST_DATA,
    payload: filters
  };
}

export function setData(data: OverviewAPIResponse): OverviewActionTypes {
  return {
    type: SET_DATA,
    payload: data
  };
}
