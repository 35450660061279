import { getJSON } from "../helpers";

import { formatISO9075, startOfDay } from "date-fns";
import { DateString, OrderType } from "../../types";

export type Overview = {
  new: number;
  in_progress: number;
  closed: number;
  cancelled: number;
};

export interface OverviewAPIResponse {
  Mixed: Overview;
  Mobile: Overview;
  "Fixed Line": Overview;
  Broadband: Overview;
  Calls: Overview;
}

export interface OverviewAPIParams {
  order_type?: OrderType | "all";
  created_after?: DateString;
}

export function getOverview(params?: OverviewAPIParams) {
  let created_after: string | undefined = params?.created_after;
  if (created_after) {
    created_after = formatISO9075(startOfDay(new Date(created_after)));
  }

  return getJSON<OverviewAPIResponse>("/Affinity/Orders/Overview", {
    ...params,
    created_after
  });
}
