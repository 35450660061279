import { useAuth } from "@akj-dev/affinity-platform";
import { useQuery } from "react-query";
import {
  broadbandSearch,
  WLR3Address,
  BroadbandSearchResponse
} from "../api/availabilityCheckApi";

/**
 * Find available broadband products at an address
 */
export function useBroadbandSearch(address: WLR3Address | "", cli: string) {
  const { accountMeta } = useAuth();
  return useQuery<BroadbandSearchResponse | false, Error>(
    ["broadbandSearch", address && address.addressReference, cli],
    async () => {
      if (address) {
        const response = await broadbandSearch(
          accountMeta.id,
          address.addressReference,
          address.cssDatabaseCode,
          address.postcode,
          cli
        );
        // TODO: can this be DRYed into another reusable hook?
        if (response.data.status === "error")
          throw Error(response.data.message);
        return response.data;
      }
      return false;
    },
    {
      enabled: false,
      retry: false
    }
  );
}
