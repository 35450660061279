import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { format, fromUnixTime } from "date-fns";

type Props = {
  createdBy: string;
  timestamp: number;
  children: string;
};

const useStyles = makeStyles({
  content: {
    wordBreak: "break-word"
  }
});

export const Comment: React.FunctionComponent<Props> = ({
  createdBy,
  timestamp,
  children
}) => {
  const classes = useStyles();

  let formattedDateTime = "";
  try {
    formattedDateTime = format(fromUnixTime(timestamp), "HH:mm - dd/MM/yyy");
  } catch (e) {
    console.warn(e);
  }

  return (
    <Box display="flex" flex={1}>
      <Box flexBasis="125px" flexShrink={0} marginRight={2}>
        <Typography variant="h5">{createdBy}</Typography>
        <Typography variant="subtitle2"> {formattedDateTime}</Typography>
      </Box>
      <Typography variant="body2" className={classes.content}>
        {children}
      </Typography>
    </Box>
  );
};
