import { call, put, select, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as autocomplete from "../../api/autocomplete";

function* searchAccounts(action: any): any {
  try {
    const response = yield call(autocomplete.accounts, action.payload);
    yield put({ type: actions.SEARCH_ACCOUNTS_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.SEARCH_ACCOUNTS_FAILED,
      payload: {
        error: "There was a problem when searching accounts. Please try again."
      }
    });

    console.log("searchAccounts error:", error.message);
  }
}

function* searchUsers(action: any): any {
  const users = yield select(store => store.autocomplete.users);

  if (users.options.length) {
    yield put({ type: actions.SEARCH_USERS_OK, payload: users });
  } else {
    try {
      const response = yield call(autocomplete.users, action.payload);
      yield put({ type: actions.SEARCH_USERS_OK, payload: response });
    } catch (error) {
      yield put({
        type: actions.SEARCH_USERS_FAILED,
        payload: {
          error: "There was a problem when searching users. Please try again."
        }
      });

      console.log("searchUsers error:", error.message);
    }
  }
}

export function* watchSearchAccounts() {
  yield takeLatest(actions.SEARCH_ACCOUNTS, searchAccounts);
}

export function* watchSearchUsers() {
  yield takeLatest(actions.SEARCH_USERS, searchUsers);
}
