import { DisplayError } from "../types";
import { BoardActionTypes, REQUEST_DATA, SET_ERROR, SET_DATA } from "./types";
import { SearchAPIParams, SearchAPIResponse } from "../../api/v1/search";

export function setError(errorForDisplay: DisplayError): BoardActionTypes {
  return {
    type: SET_ERROR,
    payload: errorForDisplay
  };
}

export function requestBoardData(params: SearchAPIParams): BoardActionTypes {
  return {
    type: REQUEST_DATA,
    payload: params
  };
}

export function setData(data: SearchAPIResponse[]): BoardActionTypes {
  return {
    type: SET_DATA,
    payload: data
  };
}
