import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, StringParam } from "use-query-params";

import { OrderInfo } from "./components/OrderInfo";
import { OrderComponentsTable } from "./components/OrderComponentsTable";
import { OrderCLIsTable } from "./components/OrderCLIsTable";
import { Status } from "../../common/Status";
import { Header } from "./components/Header";
import { SubHeader } from "./components/SubHeader";
import { TabPanel } from "./components/TabPanel";
import { Comments } from "./components/Comments";
import { requestViewDataById } from "../../../store/detail/actions";
import { RootState } from "../../../store/rootReducer";

export function OrderDetail() {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.detail.data);
  const fetching = useSelector((state: RootState) => state.detail.fetching);
  const error = useSelector((state: RootState) => state.detail.error);

  const [id] = useQueryParam("id", StringParam);
  useEffect(() => {
    if (id) {
      dispatch(requestViewDataById(id));
    }
  }, [id, dispatch]);

  return (
    <React.Fragment>
      <Header
        productType={data?.order_product_category}
        companyName={data?.customer_company_name}
        orderType={data?.order_type}
      />
      <Status loading={fetching} error={error}>
        {data && (
          <React.Fragment>
            <SubHeader
              orderType={data.order_type}
              productCategory={data.order_product_category}
              orderNumber={data.order_number}
            />
            <TabPanel
              infoPanel={
                <React.Fragment>
                  <OrderInfo
                    owner={data.created_by_for_display.name}
                    address={data.address}
                    reference={data.cust_reference}
                  />
                  {data.components ? (
                    <OrderComponentsTable components={data.components} />
                  ) : (
                    data.clis && <OrderCLIsTable clis={data.clis} />
                  )}
                </React.Fragment>
              }
              commentsPanel={<Comments orderId={data.id.toString()} />}
            />
          </React.Fragment>
        )}
      </Status>
    </React.Fragment>
  );
}
