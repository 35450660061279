import axios from "axios";

import { AuthCookies, getCookieValue } from "@akj-dev/affinity-platform";

/**
 * Axios client for making requests to Kong
 * This uses credentials and base URL set in AuthProvider via the External Users App
 */
export const kongAxios = axios.create();

// TODO: this is duped in 3 packages. Move to shared when that bit of refactoring is done?

kongAxios.interceptors.request.use(
  config => {
    const token = getCookieValue(AuthCookies.SESSION_TOKEN);
    const baseUrl = getCookieValue(AuthCookies.API_BASE_URL);
    if (token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`; // TODO: Is headers really ever undefined? From axios version bump
    }
    if (baseUrl) {
      config.baseURL = baseUrl;
    }
    return config;
  },
  error => Promise.reject(error)
);

/**
 * Converts object to form data for DC requests.
 * DC doesn't seem to understand normal post data request payloads, only form data?
 * TODO: can't we just make DC ok with standard post request payloads?
 */
export const objectToFormData = (obj: any) => {
  const f = new FormData();
  for (let key in obj) {
    f.append(key, obj[key]);
  }
  return f;
};
