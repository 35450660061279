import React from "react";
import { TabContext, TabList, TabPanel as MuiTabPanel } from "@material-ui/lab";
import { Tab, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    paddingBottom: theme.spacing(1),
    paddingRight: 0
  }
}));

interface Props {
  infoPanel: React.ReactNode;
  commentsPanel: React.ReactNode;
}

export const TabPanel: React.FunctionComponent<Props> = ({
  infoPanel,
  commentsPanel
}) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = React.useState("info");
  const handleTabChange = (event: any, selectedTab: string) => {
    setActiveTab(selectedTab);
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={handleTabChange}
        aria-label="info or comment tabs"
        indicatorColor="primary"
      >
        <Tab label="Order Info" value="info" />
        <Tab label="Comments" value="comments" />
      </TabList>
      <Divider />
      <MuiTabPanel className={classes.root} value="info">
        {infoPanel}
      </MuiTabPanel>
      <MuiTabPanel className={classes.root} value="comments">
        {commentsPanel}
      </MuiTabPanel>
    </TabContext>
  );
};
