import React from "react";
import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { LocationDescriptorObject } from "history";

type Props = {
  title: string;
  to: LocationDescriptorObject | string;
};

export const OrdersSummaryCard: React.FC<Props> = ({ title, to, children }) => {
  const useStyles = makeStyles(theme => ({
    header: {
      textTransform: "capitalize",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      textAlign: "center"
    },
    actions: {
      cursor: "pointer",
      textTransform: "capitalize",
      transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      }
    }
  }));

  const classes = useStyles();
  return (
    <Card raised>
      <CardHeader className={classes.header} title={title}></CardHeader>
      <Box display="flex">{children}</Box>
      <Link to={to} style={{ textDecoration: "none" }}>
        <CardActions className={classes.actions}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <div>View All</div>
            <ChevronRightIcon fontSize="small" />
          </Box>
        </CardActions>
      </Link>
    </Card>
  );
};
