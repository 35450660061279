import React, { useState, useCallback, useEffect } from "react";
import { throttle } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { TextField, CircularProgress } from "@material-ui/core";
import { AccountOption } from "../../types";

type Props = {
  selected: AccountOption | null;
  onSelect: (account: AccountOption | null) => void;
  optionsService: (query: string) => Promise<AccountOption[]>;
  wait: number;
  id?: string;
  label: string;
  [x: string]: any;
};

export const AccountSelect = ({
  id,
  label,
  selected,
  onSelect,
  optionsService,
  wait = 300,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<AccountOption[]>([]);

  const throttledOptionsService = useCallback(
    throttle((input: string) => optionsService(input), wait),
    []
  );

  useEffect(() => {
    let active = true;

    if (!open) {
      return;
    }

    if (!searchTerm) {
      return;
    }

    setLoading(true);
    //@ts-ignore
    throttledOptionsService(searchTerm)
      .then(results => {
        if (active) {
          setOptions([...results]);
        }
      })
      .catch(e => console.error(e))
      .finally(() => {
        if (active) {
          setLoading(false);
        }
      });

    return () => {
      active = false;
    };
  }, [throttledOptionsService, open, searchTerm]);

  const getOptionLabel = (option: AccountOption) => {
    if (option.name) {
      return `${option.id} (${option.name})`;
    }
    return option.id;
  };

  // the component struggles to match the input value with an option as they are types, so we help along..
  const inputMatchesSelected = selected
    ? inputValue === getOptionLabel(selected)
    : false;

  return (
    <Autocomplete
      value={selected}
      noOptionsText={
        !inputValue || inputMatchesSelected
          ? "Start typing to search"
          : "No matches"
      }
      onChange={(e, option) => {
        if (option) {
          // clear options (but retain the selected one as mui-autocomplete requires the selected value to have corrresponding option)
          setOptions([option]);
        } else {
          setOptions([]);
        }

        onSelect(option);
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (!value) {
          setOptions([]);
        }
        setInputValue(value);
        if (reason === "input") {
          setSearchTerm(value);
        } else if (reason === "clear" || reason === "reset") {
          setSearchTerm("");
        }
      }}
      options={options}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      selectOnFocus
      filterSelectedOptions={inputMatchesSelected}
      fullWidth
      size="small"
      id={id}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={16} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      {...rest}
    />
  );
};
