import React from "react";

import MUIDataTable, {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableOptions
} from "mui-datatables";

import {
  Box,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";

import Delete from "./Delete";
import NotificationStatus from "./Status";
import NotificationTriggers from "./Triggers";

import Autocomplete from "../../../components/Autocomplete";
import FreeSearch from "../../../components/FreeSearch";

import { Account, Autocomplete as AutocompleteInterface } from "../../../types";
import { constants } from "../../../helpers";

import {
  Notification,
  NotificationType,
  Query,
  SortDirection,
  Trigger
} from "../types";

type Props = {
  accounts: AutocompleteInterface<Account>;
  count: number;
  data: Array<Notification>;
  loading: boolean;
  query: Query;
  remove: (id: number) => void;
  resetQuery: () => void;
  searchAccounts: (query: string) => void;
  setQuery: (query: Partial<Query>) => void;
  setStatus: (id: number, is_active: boolean) => void;
};

const Component: React.FunctionComponent<Props> = ({
  accounts,
  count,
  data,
  loading,
  query,
  remove,
  resetQuery,
  searchAccounts,
  setQuery,
  setStatus
}) => {
  // Check if "sort" is equal with the given "columnName"
  // and return the value of "order" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.order : "none";

  const columns: MUIDataTableColumn[] = [
    {
      name: "account_name",
      label: "Company Name",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: 150 }
        }),
        filterOptions: {
          display: (
            filterList: any[],
            onChange: (
              val: string | string[],
              index: number,
              column: MUIDataTableColumn
            ) => void,
            index: number,
            column: object
          ) => (
            <Autocomplete
              label="Contact Name"
              name="account_id"
              placeholder="Type to search"
              loading={accounts.loading}
              options={accounts.options}
              value={
                accounts.options.find(
                  option => option.id === query.account_id
                ) || null
              }
              valueFrom="id"
              textFrom="name"
              onSelect={selected =>
                setQuery({ account_id: selected ? selected.id : "" })
              }
              onSearch={query => searchAccounts(query)}
              shrink
            />
          )
        },
        filterType: "custom",
        sortDirection: getSortDirection("account_name")
      }
    },
    {
      name: "contact.name",
      label: "Username",
      options: {
        filterOptions: {
          display: (
            filterList: any[],
            onChange: () => void,
            index: number,
            column: object
          ) => (
            <FreeSearch
              disabled={loading}
              fullWidth
              label="Username"
              name="name"
              id="name"
              variant="outlined"
              value={query.name || ""}
              size="small"
              onDebouncedChange={(value: string) =>
                setQuery({ name: value || "" })
              }
              wait={constants.wait}
            />
          )
        },
        filterType: "custom",
        sortDirection: getSortDirection("contact.name")
      }
    },
    {
      name: "contact.email",
      label: "Email Address",
      options: {
        filterOptions: {
          display: (
            filterList: any[],
            onChange: () => void,
            index: number,
            column: object
          ) => (
            <FreeSearch
              disabled={loading}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              variant="outlined"
              value={query.email || ""}
              size="small"
              onDebouncedChange={(value: string) =>
                setQuery({ email: value || "" })
              }
              wait={constants.wait}
            />
          )
        },
        filterType: "custom",
        sortDirection: getSortDirection("contact.email")
      }
    },
    {
      name: "type",
      label: "Product",
      options: {
        filterOptions: {
          display: (
            filterList: any[],
            onChange: () => void,
            index: number,
            column: object
          ) => (
            <TextField
              disabled={loading}
              fullWidth
              variant="outlined"
              select
              id="type"
              label="Product"
              name="type"
              value={query.type || ""}
              onChange={event =>
                setQuery({ type: event.target.value as NotificationType })
              }
              size="small"
            >
              {constants.notificationTypes.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )
        },
        filterType: "custom",
        sortDirection: getSortDirection("type")
      }
    },
    {
      name: "fields",
      label: "Trigger",
      options: {
        customBodyRender: (fields: any) => (
          <NotificationTriggers triggers={fields} />
        ),
        filterOptions: {
          display: (
            filterList: any[],
            onChange: () => void,
            index: number,
            column: object
          ) => (
            <Autocomplete
              label="Select Triggers"
              loading={loading}
              multiple
              name="values"
              onSelect={(triggers: Array<Trigger>) =>
                setQuery({ values: triggers.map(trigger => trigger.value) })
              }
              options={constants.triggers.filter(trigger =>
                trigger.types.find(type => type === query.type)
              )}
              textFrom="value"
              value={constants.triggers.filter(
                trigger =>
                  trigger.types.find(type => type === query.type) &&
                  (query.values || []).indexOf(trigger.value) >= 0
              )}
            />
          )
        },
        filter: query.type ? true : false,
        filterType: "custom",
        sort: false
      }
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (is_active: any, tableMeta: any) => {
          const notification = data[tableMeta.rowIndex];

          return (
            <NotificationStatus
              key={notification.id}
              id={notification.id}
              active={notification.is_active}
              setStatus={setStatus}
            />
          );
        },
        filterOptions: {
          display: (
            filterList: any[],
            onChange: () => void,
            index: number,
            column: object
          ) => (
            <FormControlLabel
              disabled={loading}
              control={
                <Switch
                  checked={query.only_active ? true : false}
                  onChange={event =>
                    setQuery({ only_active: event.target.checked ? 1 : 0 })
                  }
                  name="filter-only_active"
                  color="primary"
                />
              }
              label={
                query.only_active
                  ? "Show only active notifications"
                  : "Show all notifications"
              }
            />
          )
        },
        filterType: "custom",
        sortDirection: getSortDirection("is_active")
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (id: number, tableMeta: any) => {
          return <Delete key={id} id={id} onClick={remove} />;
        },
        filter: false,
        sort: false
      }
    }
  ];

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,

    onFilterChange: (
      changedColumn: string,
      filterList: any[],
      type: FilterType | "chip" | "reset"
    ) => {
      if (type === "reset") {
        resetQuery();
      }
    },

    responsive: "scrollFullHeight",
    serverSide: true,

    serverSideFilterList: [],

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: count,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn: string, direction: string) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        order: direction === "ascending" ? "asc" : "desc"
      });
    },

    setTableProps: () => ({
      size: "small"
    })
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Notifications</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export default Component;
